<template>
  <div>
    <BannerDetail />
    <div class="submit-form">
      <h3>留下你的联系方式，艾奇为您提供服务</h3>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="220px"
        class="demo-ruleForm"
        :size="'default'"
        status-icon
      >
        <el-form-item label="您的姓名" prop="name">
          <el-input v-model="ruleForm.name" />
        </el-form-item>
        <el-form-item label="公司名称" prop="companyName">
          <el-input v-model="ruleForm.companyName" />
        </el-form-item>
        <el-form-item label="公司所在地" prop="companyAddress">
          <el-input v-model="ruleForm.companyAddress" />
        </el-form-item>
        <el-form-item label="联系方式" prop="contactPhone" required>
          <el-input v-model="ruleForm.contactPhone" />
        </el-form-item>
        <el-form-item label="您的企业是哪种类型？" prop="companyType">
          <el-checkbox-group v-model="ruleForm.companyType">
            <el-checkbox label="制造厂商" name="comType" />
            <el-checkbox label="方案商" name="comType" />
            <el-checkbox label="品牌商" name="comType" />
            <el-checkbox label="集成商" name="comType" />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="您想咨询哪类智能产品？" prop="productionType">
          <el-checkbox-group v-model="ruleForm.productionType">
            <el-checkbox label="家电厨电" name="productionType" />
            <el-checkbox label="美容人护" name="productionType" />
            <el-checkbox label="智能宠物" name="productionType" />
            <el-checkbox label="运动健康" name="productionType" />
            <el-checkbox label="户外出行" name="productionType" />
            <el-checkbox label="智能家居" name="productionType" />
            <el-checkbox label="其它" name="productionType" />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="如果你想在已有产品上添加功能，那么您希望增加哪些功能？" prop="capibility">
          <el-checkbox-group v-model="ruleForm.capibility" style="line-height: 80px;">
              <el-checkbox label="蓝牙BLE" name="productionType" />
              <el-checkbox label="可视化" name="productionType" />
              <el-checkbox label="WIFI"  name="productionType" />
              <el-checkbox label="超声波"  name="productionType" />
              <el-checkbox label="语音控制"  name="productionType" />
              <el-checkbox label="APP"  name="productionType" />
              <el-checkbox label="其它" name="productionType" />
            </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)">
            提交
          </el-button>
          <el-button @click="resetForm(ruleFormRef)">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script setup>
import BannerDetail from './BannerDetail.vue'
import { reactive, ref } from 'vue'

const ruleFormRef = ref()
const ruleForm = reactive({
  name: '',
  companyName: '',
  companyAddress: '',
  contactPhone: '',
  companyType: ['制造厂商', '方案商'],
  productionType: [1, 3, 5],
  capibility: [1, 2, 3]
})

const rules = reactive({
  name: [
    { required: true, message: '请输入姓名', trigger: 'blur' }
  ],
  companyName: [
    { required: true, message: '请输入公司名称', trigger: 'blur' }
  ],
  contactPhone: [
    { required: true, message: '请输入联系方式', trigger: 'blur' }
  ]
})

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      const { companyType } = ruleForm
      console.log('submit!', companyType)
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}

// const options = Array.from({ length: 10000 }).map((_, idx) => ({
//   value: `${idx + 1}`,
//   label: `${idx + 1}`
// }))
</script>
<style scoped>
h3{ text-align: center;}
.submit-form{ width: 1000px; margin: 0 auto;}
</style>
