<template>
  <div>
    <BannerDetail />
    <div class="download">
      <div class="search">
        <el-input class="input-text" :prefix-icon="Search" v-model="input" placeholder="Please input" /><el-button @click="searchHandler" plain>搜索</el-button>
      </div>
      <el-table class="table-download" :data="tableData" border style="width: 100%">
        <el-table-column prop="icon" label="图标" width="180" />
        <el-table-column prop="name" label="名称" width="180" />
        <el-table-column prop="sumary" label="简介" />
        <el-table-column prop="link" label="下载链接" />
      </el-table>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import BannerDetail from './BannerDetail.vue'
import { Search } from '@element-plus/icons-vue'
const input = ref('')
const searchHandler = () => {
}
const tableData = [
  {
    icon: 'icon',
    name: 'Tom',
    sumary: '简介1'
  },
  {
    icon: 'icon2',
    name: 'Tom',
    sumary: '简介2'
  },
  {
    icon: 'icon3',
    name: 'Tom',
    sumary: '简介3'
  },
  {
    icon: 'icon4',
    name: 'Tom',
    sumary: '简介4'
  }
]
</script>
<style>
.download{ width: 900px; margin:0 auto; padding: 50px;}
.download .search{ width: 600px; margin: 0 auto; background-color: #e6e6e6; border-radius: 10px; padding: 5px;}
.download .input-text{ width: 520px; margin-right: 10px;}
.download .table-download{ margin: 20px auto;}
</style>
